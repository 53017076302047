import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PageBuilder from '../components/pageBuilder';
import SEO from '../components/seo';
import IEBanner from '../components/iebanner';

export default function SingleNewsPage({ data }) {

  const { pageBuilder } = data.page;
  const { _rawPageBuilder } = data.page;
  return (
    <>
      <SEO title={pageBuilder.pageName}/>
      <IEBanner />
      <Layout>
        <PageBuilder pageBuilder={pageBuilder} _rawPageBuilder={_rawPageBuilder} />
      </Layout>
    </>
  );
}

// $slug comes from gatsby-node context
export const data = graphql
  `
  query($slug: String!)  {
    page: sanityNews(slug: {current: { eq: $slug}}) {
      _rawPageBuilder(resolveReferences: { maxDepth: 10 })
      pageBuilder {
        ...PageBuilderFragment
      }
    }
  }
  `
;